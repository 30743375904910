import { STORAGE_UPDATED_AT_KEY, STORAGE_USER_KEY } from 'types/others'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import client from 'requests/client'
import { unsubscribe } from 'utils/others/notifications'
import { isResolved } from 'utils/others/promise-status'
import router from 'pages/_app/app.router'
import type { ScraperAll } from 'types/types'
import type { ScraperValues } from 'types/consts/scraper.const'
import type { FavoritesType, SearchType, UserType } from 'requests/types/helpers'

export interface UserStateType {
    /** Current user */
    me: UserType | null
    /** Set me raw user. */
    setMe: (me: UserStateType['me']) => void
    /** Update Me User Settings */
    updateMeSettings: (me: Pick<NonNullable<UserStateType['me']>, 'username' | 'email' | 'currency' | 'country'>) => void
    /** Update user info */
    updateMe: ({
        data,
        action,
    }: {
        /** Data */
        data: SearchType | Array<SearchType>
        /**
         * Action
         * @default 'upsert'
         */
        action?: 'upsert' | 'remove'
    }) => void
    /** SetItemsFound */
    updateFavorites: ({
        favorites,
        searchId,
        scraperValue,
    }: {
        /** Url */
        favorites: FavoritesType
        /** SearchId */
        searchId: SearchType['_id']
        /** ScraperValue */
        scraperValue: ScraperValues
    }) => void
    /**
     * Sign out
     * @param action Is with logout in API. If `false`, mean that all tokens are expired so we can't call API to clean some stuff
     */
    signOut: (withApi: boolean) => void
}

const useUserStore = create<UserStateType>()(
    set => ({
        me: (() => {
            try {
                return JSON.parse(localStorage.getItem(STORAGE_USER_KEY)!)
            } catch (e) {
                return null
            }
        })(),
        setMe: me => {
            localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(me))
            set({ me })
        },
        updateMeSettings: me => {
            set(state => {
                const meUpdated = { ...state.me!, ...me }
                localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(meUpdated))
                return { me: meUpdated }
            })
        },
        updateMe: ({ data, action }) => {
            set(state => {
                const userData = (() => {
                    if (Array.isArray(data)) {
                        const newUserData = state.me!
                        newUserData.searches = data
                        return newUserData
                    }
                    const newUserData = state.me!
                    const searchIndex = newUserData.searches?.findIndex(search => search._id === data._id) ?? -1

                    switch (action || 'upsert') {
                        case 'upsert':
                            if (searchIndex > -1) {
                                newUserData.searches[searchIndex] = data
                            } else {
                                newUserData.searches.push(data)
                            }
                            break
                        case 'remove':
                            newUserData.searches.splice(searchIndex, 1)
                            break
                        default:
                            throw new Error('Invalid me update')
                    }

                    return newUserData
                })()

                localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.me))

                return { me: userData }
            })
        },
        updateFavorites: ({ favorites, searchId, scraperValue }) =>
            set(state => {
                const userData = state.me!
                const searchIndex = userData.searches?.findIndex(search => search._id === searchId)

                if (searchIndex === -1) {
                    return {}
                }

                const newUrl = favorites?.elements?.[0]?.url

                if (!newUrl) {
                    return {}
                }

                const scraper = userData.searches[searchIndex]?.[scraperValue] as ScraperAll | undefined

                const urlIndexFavorites = scraper?.favorites?.elements?.findIndex(element => element.url === newUrl) ?? -1

                // If not found, add it
                if (urlIndexFavorites === -1) {
                    scraper?.favorites?.elements?.push({ url: newUrl })

                    const urlIndexExcludes = scraper?.excludes?.elements?.findIndex(element => element.url === newUrl) ?? -1
                    if (urlIndexExcludes > -1) {
                        scraper?.excludes?.elements?.splice(urlIndexExcludes, 1)
                    }
                } else {
                    // Otherwise remove it
                    scraper?.favorites?.elements?.splice(urlIndexFavorites, 1)

                    const urlIndexExcludes = scraper?.excludes?.elements?.findIndex(element => element.url === newUrl)
                    if (urlIndexExcludes === -1) {
                        scraper?.excludes?.elements?.push({ url: newUrl })
                    }
                }

                // Sort favorites elements
                if (scraper?.favorites?.elements) {
                    scraper.favorites.elements = scraper.favorites.elements.sort((a, b) => (a.url || '').localeCompare(b.url || ''))
                }

                // Sort excludes elements
                if (scraper?.excludes?.elements) {
                    scraper.excludes.elements = scraper.excludes.elements.sort((a, b) => (a.url || '').localeCompare(b.url || ''))
                }

                localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(state.me))

                return { me: userData }
            }),
        signOut: withApi => {
            if (withApi) {
                ;(async () => {
                    // Get a new JWT in case the one is storage is already expire, so we can Unsubscribe just then
                    await client.PATCH('/api/users/authentication/refresh')

                    // `navigator.serviceWorker.ready` will be forever Pending if there's no SW. So we check promise status
                    if (navigator.serviceWorker && (await isResolved(navigator.serviceWorker.ready))) {
                        await unsubscribe({})
                    }

                    await client.POST('/api/users/authentication/logout')
                })()
            }

            localStorage.removeItem(STORAGE_USER_KEY)
            localStorage.removeItem(STORAGE_UPDATED_AT_KEY)

            const redirectUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`

            if (window.location.pathname !== '/login') {
                router.navigate({ to: '/login', search: { redirect: redirectUrl !== '/' ? redirectUrl : undefined } })
            }

            set({ me: null })
        },
    }),
    shallow,
)

export default useUserStore
