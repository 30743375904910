import type { SearchItemsSearch } from 'pages/_app/app.router'
import type { ScraperAll } from 'types/types'

/**
 * GetSearchQuery
 * @param scraper scraper
 */
export default function getSearchQuery(scraper?: ScraperAll): SearchItemsSearch {
    return {
        text: scraper?.filtersPre?.text || undefined,
        isNew: scraper?.filtersPre?.isNew || undefined,
        isNewPrice: scraper?.filtersPre?.isNewPrice || undefined,
        isFavorite: scraper?.filtersPre?.isFavorite || undefined,
    }
}
