import { useRef, useCallback } from 'react'
import { usePrevious, useUnmount } from 'react-use'
import { CARD_IS_DRAGGING_CLASSNAME, CARD_IS_DRAGOVER_CLASSNAME } from 'pages/index/components/card/card.constants'
import { useMutation } from '@tanstack/react-query'
import { searchesQueries } from 'requests/queries'
import useHomeStore, { type HomeState } from 'stores/home.store'
import useNavigationStore from 'stores/navigation.store'
import useUserStore from 'stores/user.store'
import type IndexCards from 'pages/index/components/card/card.component'
import type { ComponentProps, ElementRef, RefObject } from 'react'
import type { Status } from 'types/status'

export interface UseIndexSortReturns extends Pick<HomeState, 'isEditing'> {
    /** ParentSearchCards */
    parentSearchCards: RefObject<ElementRef<typeof IndexCards>>
    /** OnDrag */
    onDrag: NonNullable<ComponentProps<'div'>['onDrag']>
    /** OnDragOver */
    onDragOver: NonNullable<ComponentProps<'div'>['onDragOver']>
    /** OnDragLeave */
    onDragLeave: NonNullable<ComponentProps<'div'>['onDragLeave']>
    /** OnDragEnd */
    onDragEnd: NonNullable<ComponentProps<'div'>['onDragEnd']>
    /** OnDrop */
    onDrop: NonNullable<ComponentProps<'div'>['onDrop']>
    /** SaveChange */
    saveChange: () => void
    /** Status */
    status: Status
}

/**
 * UseIndexSort
 */
export default function useIndexSort(): UseIndexSortReturns {
    const updateMe = useUserStore(state => state.updateMe)
    const setAlert = useNavigationStore(state => state.setAlert)

    const isEditing = useHomeStore(state => state.isEditing)
    const setIsEditing = useHomeStore(state => state.setIsEditing)
    const setAreDisableSearchVisible = useHomeStore(state => state.setAreDisableSearchVisible)

    /** Element dragging */
    const fromSearchCard = useRef<ElementRef<'div'> | null>(null)

    /** Place drag element before this one */
    const toSearchCard = useRef<ElementRef<'div'> | null>(null)

    /** Parent container */
    const parentSearchCards = useRef<ElementRef<typeof IndexCards>>(null)

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const prevIsEditing = usePrevious(isEditing)

    /** On drag search card */
    const onDrag = useCallback<UseIndexSortReturns['onDrag']>(ev => {
        ev.preventDefault()

        fromSearchCard.current = (ev.target as HTMLElement)?.closest('[draggable="true"]')

        // Add dragging class
        if (fromSearchCard.current) {
            fromSearchCard.current.classList.add(CARD_IS_DRAGGING_CLASSNAME)
        }
    }, [])

    /** On drag over search card */
    const onDragOver = useCallback<UseIndexSortReturns['onDragOver']>(ev => {
        ev.preventDefault()

        toSearchCard.current = (ev.target as HTMLElement)?.closest('[draggable="true"]')

        // Add drag over class
        if (toSearchCard.current) {
            toSearchCard.current.classList.add(CARD_IS_DRAGOVER_CLASSNAME)
        }
    }, [])

    /** On drag leave a search card */
    const onDragLeave = useCallback<UseIndexSortReturns['onDragOver']>(ev => {
        ev.preventDefault()

        // Remove drag over class if the "to" element is the same as the targeted
        if (toSearchCard.current && ev.target === toSearchCard.current) {
            toSearchCard.current.classList.remove(CARD_IS_DRAGOVER_CLASSNAME)
            toSearchCard.current = null
        }
    }, [])

    /** On drag end, reset elements */
    const onDragEnd = useCallback<UseIndexSortReturns['onDragEnd']>(ev => {
        ev.preventDefault()

        if (fromSearchCard.current) {
            fromSearchCard.current.classList.remove(CARD_IS_DRAGGING_CLASSNAME)
            fromSearchCard.current.classList.remove(CARD_IS_DRAGOVER_CLASSNAME)
            fromSearchCard.current = null
        }

        if (toSearchCard.current) {
            toSearchCard.current.classList.remove(CARD_IS_DRAGGING_CLASSNAME)
            toSearchCard.current.classList.remove(CARD_IS_DRAGOVER_CLASSNAME)
            toSearchCard.current = null
        }
    }, [])

    /** On drop search card */
    const onDrop = useCallback<UseIndexSortReturns['onDrop']>(ev => {
        ev.preventDefault()

        if (fromSearchCard.current && toSearchCard.current && parentSearchCards.current) {
            // Copy element to keep attributes values
            const from = fromSearchCard.current.cloneNode(true) as ElementRef<'div'>
            const to = toSearchCard.current.cloneNode(true) as ElementRef<'div'>
            // Switch content of the elements
            fromSearchCard.current.appendChild(toSearchCard.current.firstElementChild as Node)
            toSearchCard.current.appendChild(fromSearchCard.current.firstElementChild as Node)
            // Switch attributes
            toSearchCard.current.setAttribute('data-id', from.getAttribute('data-id') || '')
            toSearchCard.current.setAttribute('data-index', from.getAttribute('data-index') || '')
            toSearchCard.current.setAttribute('class', from.getAttribute('class') || '')
            fromSearchCard.current.setAttribute('data-id', to.getAttribute('data-id') || '')
            fromSearchCard.current.setAttribute('data-index', to.getAttribute('data-index') || '')
            fromSearchCard.current.setAttribute('class', to.getAttribute('class') || '')
        }
    }, [])

    const { mutate: sort, status } = useMutation({
        ...searchesQueries.sort(),
        onSuccess({ searches }) {
            updateMe({ data: searches })
        },
    })

    /** Save change */
    const saveChange = useCallback(() => {
        setAlert(null)
        sort(
            {
                body: {
                    searches: [...(parentSearchCards.current?.childNodes ?? [])]
                        .map(el => (el as HTMLElement).getAttribute('data-id') ?? '')
                        .map((id, i) => ({
                            _id: id,
                            order: i + 1,
                        })),
                },
            },
            {
                onSuccess() {
                    setAlert({ content: 'Searches order updated', type: 'success' })
                    setAreDisableSearchVisible(false)
                    setIsEditing(false)
                },
            },
        )
    }, [sort, setAlert, setAreDisableSearchVisible, setIsEditing])

    // Resort search card if search canceled
    if (!isEditing && prevIsEditing) {
        ;([...(parentSearchCards.current?.childNodes ?? [])] as Array<HTMLElement>)
            .sort((a, b) => {
                if (a.getAttribute('data-index')) {
                    return a.getAttribute('data-index')?.localeCompare(b.getAttribute('data-index') ?? '') ?? 0
                }
                return 1
            })
            .forEach(el => parentSearchCards.current?.appendChild(el))
    }

    useUnmount(() => {
        setIsEditing(false)
    })

    return {
        isEditing,
        parentSearchCards,
        onDrop,
        onDragOver,
        onDragLeave,
        onDragEnd,
        onDrag,
        saveChange,
        status,
    }
}
