import { CountryName, CountryContinent } from 'types/consts'
import type { CountryCodeAllValues } from 'types/consts/countries/country-code.const'
import type { TabsItems } from 'pages/searches/[searchid]/index/hooks/items.hook'
import type { SearchItemsSearch } from 'pages/_app/app.router'

/**
 * Get filtered items
 */
export default function getFilteredItems({
    items,
    filters: { text, isNew, isNewPrice, isFavorite },
}: {
    /** Items to filter */
    items: TabsItems['']
    /** Filters to apply */
    filters: SearchItemsSearch
}) {
    return items.filter(item => {
        if (text) {
            /**
             * Clean a string by removing accent and more
             * @param str Input
             */
            const clean = (str: string | null) =>
                str
                    ?.toLowerCase()
                    ?.normalize('NFD')
                    ?.replace(/\p{Diacritic}/gu, '') ?? ''

            /** Text query cleaned */
            const textCleaned = clean(text)

            /** Text query to filter minus the banned elements */
            const textToFilter = textCleaned
                .split(' ')
                .filter(x => !x.startsWith('-') || x === '-')
                .join(' ')

            /** Banned words starting with a `-` */
            const bannedWords = textCleaned
                .split(' ')
                .filter(x => x.startsWith('-'))
                .map(x => x.replace('-', ''))
                .filter(x => x)

            // Check if at least one banned word match
            // eslint-disable-next-line no-restricted-syntax
            for (const bannedWord of bannedWords) {
                // If the banned word is in the item, remove it
                if (
                    clean(item.title)?.includes(bannedWord) ||
                    clean(item.category)?.includes(bannedWord) ||
                    clean(item.seller?.name)?.includes(bannedWord) ||
                    clean(CountryName[item.from?.code as CountryCodeAllValues])?.includes(bannedWord) ||
                    clean(CountryContinent[item.from?.code as CountryCodeAllValues])?.includes(bannedWord)
                ) {
                    return false
                }
            }

            // If the text to filter is not is the item, remove it
            if (
                !clean(item.title)?.includes(textToFilter) &&
                !clean(item.category)?.includes(textToFilter) &&
                !clean(item.seller?.name)?.includes(textToFilter) &&
                !clean(CountryName[item.from?.code as CountryCodeAllValues])?.includes(textToFilter) &&
                !clean(CountryContinent[item.from?.code as CountryCodeAllValues])?.includes(textToFilter)
            ) {
                return false
            }
        }

        // Remove not new item
        if (isNew && !item.isNew) {
            return false
        }

        // Remove not new price item
        if (isNewPrice && !item.price?.difference?.original) {
            return false
        }

        // Remove not favorite item
        if (isFavorite && !item.isFav) {
            return false
        }

        return true
    })
}
