import { useCallback, useMemo } from 'react'
import { TABS_CONTAINER_CLASSNAME } from 'pages/searches/[searchid]/index/components/tabs/tabs.constant'
import getSearchQuery from 'pages/searches/[searchid]/index/functions/get-search-query.function'
import useSearchStore from 'stores/search.store'
import { getRouteApi } from '@tanstack/react-router'
import type { UseIndexItemsReturns } from 'pages/searches/[searchid]/index/hooks/items.hook'
import type { SearchidSearchesContextType } from 'pages/searches/[searchid]/index/index.context'

export interface UseIndexNavigationParams
    extends Pick<SearchidSearchesContextType, 'fetchItems' | 'currentScraperIndex' | 'scraperValuesEnable' | 'searchUser'>,
        Pick<UseIndexItemsReturns, 'cancelAllRunningRequests'> {}

export interface UseIndexNavigationReturns {
    /** GoToIndex */
    goToIndex: (newIndex: number) => void
    /** GoToNext */
    goToNext: () => void
    /** GoToPrev */
    goToPrev: () => void
}

const route = getRouteApi('/layout/searches/$searchid')

/**
 * UseIndexNavigation to handle navigation between scrapers
 */
export default function useIndexNavigation({
    currentScraperIndex,
    scraperValuesEnable,
    searchUser,
    fetchItems,
    cancelAllRunningRequests,
}: UseIndexNavigationParams): UseIndexNavigationReturns {
    const navigate = route.useNavigate()
    const setItemsFound = useSearchStore(state => state.setItemsFound)
    const setLastSearchOn = useSearchStore(state => state.setLastSearchOn)
    const searchId = route.useParams({ select: params => params.searchid })

    const scrapersUrl = useMemo(
        () =>
            scraperValuesEnable.map(scraperValue => ({
                hash: scraperValue,
                search: getSearchQuery(searchUser?.[scraperValue]),
            })),
        [scraperValuesEnable, searchUser],
    )

    const goToIndex = useCallback<UseIndexNavigationReturns['goToIndex']>(
        newIndex => {
            if (newIndex > -1) {
                navigate({
                    params: { searchid: searchId },
                    hash: scrapersUrl[newIndex]!.hash,
                    search: scrapersUrl[newIndex]!.search,
                    replace: true,
                    resetScroll: false,
                })
                fetchItems(newIndex)
            } else {
                navigate({ hash: '', replace: true, resetScroll: false })
                cancelAllRunningRequests()
                setLastSearchOn(null)
                setItemsFound({ total: null, current: null })
            }
        },
        [navigate, searchId, scrapersUrl, fetchItems, cancelAllRunningRequests, setLastSearchOn, setItemsFound],
    )

    const goToNext = useCallback(() => {
        if (currentScraperIndex + 1 <= scraperValuesEnable.length - 1) {
            // Go to Next
            goToIndex(currentScraperIndex + 1)
        } else {
            // Go back to first
            goToIndex(-1)
            document.querySelector(`.${TABS_CONTAINER_CLASSNAME}`)?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [currentScraperIndex, goToIndex, scraperValuesEnable.length])

    const goToPrev = useCallback(() => {
        if (currentScraperIndex - 1 >= -1) {
            // Go to prev
            goToIndex(currentScraperIndex - 1)
        } else {
            // Got to last
            goToIndex(scraperValuesEnable.length - 1)
            document.querySelector(`.${TABS_CONTAINER_CLASSNAME}`)?.scrollTo({ top: 0, left: 99999, behavior: 'smooth' })
        }
    }, [currentScraperIndex, goToIndex, scraperValuesEnable.length])

    return {
        goToNext,
        goToPrev,
        goToIndex,
    }
}
