import { isHtml } from 'utils/dom'
import type { ToStringRecursively } from 'types/types'

interface ExceptionType {
    /** Status */
    status?: number
    /** Message */
    message: string
    /** Errors */
    errors?: Array<ExceptionValidationType>
}

interface ExceptionValidationType {
    /** Property */
    property?: string
    /** Children */
    children?: Array<ExceptionValidationType>
    /** Constraints */
    constraints?: string
}

/**
 * ReduceInvalidFields
 * @param data Array of ChildType
 */
const reduceInvalidFields = (data: Array<ExceptionValidationType>): unknown =>
    data?.reduce(
        (a, b) => ({
            ...a,
            [b.property as string]: b.children?.length ? reduceInvalidFields(b.children) : b.constraints,
        }),
        {},
    ) ?? undefined

/**
 * Exception
 */
export default class Exception<T = Record<string, string>> extends Error {
    status: number

    invalidFields?: ToStringRecursively<T>

    constructor({ errors, message, status, cause }: ExceptionType & Pick<Error, 'cause'>) {
        super()

        this.status = status && !Number.isNaN(status) ? status : 500

        if (!isHtml(message)) {
            this.message = message?.toString() || 'Something bad happened'
        } else {
            const html = document.createElement('html')
            html.innerHTML = message || ''
            this.message =
                html.querySelector('head title')?.textContent || html.querySelector('h1')?.textContent || 'Something bad happened'
        }

        this.invalidFields = errors?.length ? (reduceInvalidFields(errors) as ToStringRecursively<T>) : undefined

        this.cause = cause
    }
}

/**
 * Generate exception from Fetch error
 * @param error Error
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function generateException(error: any): Exception {
    return new Exception({
        message: error?.message ?? error?.toString(),
        errors: error?.errors ?? [],
        status: error?.status,
        cause: error,
    })
}
