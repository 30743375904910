import getUuid from 'utils/others/get-uuid'
import { createWithEqualityFn as create } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'
import router from 'pages/_app/app.router'

export interface SearchState {
    /** Last search on */
    lastSearchOn: Date | null
    /** Items found from API to display in layout */
    itemsFound: {
        /** Total */
        total: number | null
        /** Current */
        current: number | null
    }
    /** Is quickview menu swiping */
    isQuickviewOpen: boolean
    /** Are disable scraper visible  */
    areDisableScraperVisible: boolean
    /** Page key suffix to reset render */
    pageKey: string
    /** SetLastSearchOn */
    setLastSearchOn: (lastSearchOn: SearchState['lastSearchOn']) => void
    /** SetItemsFound */
    setItemsFound: (itemsFound: SearchState['itemsFound']) => void
    /** SetIsQuickviewOpen */
    setIsQuickviewOpen: (isQuickviewOpen: SearchState['isQuickviewOpen']) => void
    /** SetAreDisableScraperVisible */
    setAreDisableScraperVisible: (areDisableScraperVisible: SearchState['areDisableScraperVisible']) => void
}

const useSearchStore = create<SearchState>()(
    set => ({
        lastSearchOn: null,
        itemsFound: { current: null, total: null },
        isQuickviewOpen: false,
        areDisableScraperVisible: false,
        pageKey: getUuid(),
        setLastSearchOn: lastSearchOn => set({ lastSearchOn }),
        setItemsFound: itemsFound => set({ itemsFound }),
        setIsQuickviewOpen: isQuickviewOpen => set({ isQuickviewOpen }),
        setAreDisableScraperVisible: areDisableScraperVisible => {
            router.navigate({ hash: '', replace: true, resetScroll: false })
            set({ areDisableScraperVisible, pageKey: getUuid(), lastSearchOn: null, itemsFound: { total: null, current: null } })
        },
    }),
    shallow,
)

export default useSearchStore
